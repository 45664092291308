<template>
        <ValidationObserver ref="observer" >
            <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                <template v-slot:col-1>
                <app-input label="Vul hier je BIG registratienummer in" 
                           v-model="cBigNumber" 
                           validatorRules="required|digits:11" 
                           validatorName="BIG registratienummer" 
                           mask="###########" 
                           v-on:blur="checkBig" 
                           @change="changingBig" 
                           @keypress="preventLetterInput"
                           :currentError="!loading_check && (checked || fInformation)"
                           class="mt-1"
                            />
                </template>
                <template v-slot:col-2>
                    <b-col md="2" xl="2" class="pt-3" v-if="loading_check">
                        <b-spinner/>
                    </b-col>
                    <b-card v-if="!loading_check && (checked || fInformation)" v-bind:class="{ 'app-card-info': fInformation, 'app-card-warning': !fInformation }">
                        <div class="font-weight-bolder big-data-inf-large">{{ fInformation ? JSON.parse(cBigData.big_data_main).gender + " " + JSON.parse(cBigData.big_data_main).mailing_name : "BIG-geregistreerd" }}</div>
                        <div class="big-data-inf-large">{{ fInformation ? JSON.parse(cBigData.big_data_main).professional_group_description : "Verkeerd nummer" }}</div>
                    </b-card>
                </template>
            </app-row-left-label>
        </ValidationObserver>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: {
        bigData: {type: Object},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
    },

    created(){
       if (this.cBigData.big_number !== undefined && this.cBigData.big_number !== null && this.cBigData.big_data_main !== null) {
           this.fInformation = true;
           this.checked_big_number = this.cBigData.big_number;
       } 
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cBigData: {
            get() { return this.bigData },
            set(value) { this.$emit('update:args', value) }
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cBigNumber:{
            get() {return this.cBigData.big_number},
            set(value) { this.clearError(value),  this.cBigData.big_number = value}
        }
    },

    data() {
        return {
            loading: false,
            fInformation: false,
            fIncorrectNumber: false,
            checked_big_key: "big",
            checked_big_number: "",
            loading_check: false,
            checked:false,
            opt: [ { value: 1, text: "Ja" }, { value: 0, text: "Nee" }],
        };
    },

    methods: {
   
      changingBig() {
           this.fIncorrectNumber = false;
           this.cBigData.big_data_main = null;
           this.fInformation = false;
           this.checked_big_number = "";
       },


       checkBig() {
            if (this.cBigData.big_number === undefined || this.cBigData.big_number === null || (this.cBigData.big_number !== null && this.cBigData.big_number.toString().length !== 11) || (this.checked_big_number !== null && this.cBigData.big_number !== null && this.checked_big_number.toString() === this.cBigData.big_number.toString())) {
                return;
            }   
                this.loading_check = true;
                this.fInformation = false;
                this.cBigData.big_data_main = null;

                axios
                    .get("core/big-number/check/" + this.cBigData.big_number)
                    .then((res) => {
                        if(res.data !== 0) {
                            this.cBigData.big_data_main = JSON.stringify(res.data);
                            this.cBigData.big_check_date = moment().format('DD-MM-YYYY HH:mm:ss');
                            this.fInformation = true;
                            this.fIncorrectNumber = false; 
                            this.checked_big_key = "big";                           
                            
                        } else {
                            this.fInformation = false;
                            this.checked_big_key = "";
                            this.fIncorrectNumber = true;
                        }
                        this.checked = true;
                        this.checked_big_number = this.cBigData.big_number;
                        this.loading_check = false;
                    })
                    .catch((error) => {
                        this.loading_check = false;
                        console.error(`error during request: ${error}`);
                    });  
                    
                this.$refs.observer.validate();
        },

        preventLetterInput($event){
            if ($event.keyCode < 48 || $event.keyCode > 57 ) {
                $event.preventDefault();
            }
         },

         clearError(big){
            this.checked_big_key = 'big';
            if(this.checked_big_number == big && !this.fIncorrectNumber){
                this.fInformation = true;
            }

            if(big.toString().length != 11){
                this.fInformation = false;
            }
            
            if((big !== null 
                    && big.toString().length > 0 
                    && big.toString().length < 11) 
                    || (this.fIncorrectNumber && big !== null 
                        && big.toString().length === 11)){
                             this.fInformation = false;
                             this.checked_big_key = '';
                        }
            if(this.$refs.observer != undefined){
                this.$refs.observer.validate();
            }
        },
    },
};
</script>

<style scoped>
.big-data-inf-large{
    font-size:14px
}

.big-data-inf-small{
    font-size:12px
}
.min-height-form{
    min-height:75px
}
</style>
