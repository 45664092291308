<template>
     <div>
        <label v-if="label" class="app-input-top-label">{{label}}</label>
        <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
        <validation-provider v-slot="{ errors}" ref="provider" :vid="validatorId" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <b-input-group
                class="app-input-group" 
                        :class="{'app-input-group-password': !disabled, 
                        'app-input-group-disabled app-password-group-disabled': disabled, 
                        'app-input-group-error': ((errors.length > 0 && errors[0] != '') || currentError) && !disabled}">
                <input 
                            ref="field" 
                            v-model="update_value"
                            :id="id" 
                            :name="name"
                            :type="visible_password ? 'text' : 'password'" 
                            :maxLength="cMaxLength" 
                            :disabled="disabled" 
                            :placeholder="placeholder"
                            :autocomplete="false"
                            class="app-local-copy"
                             :class="{
                                [customInputClass]: true,
                                'app-input-filled': update_value != null && update_value.length > 0, 
                                'app-password-disabled': disabled, 
                                'app-password-error': ((errors.length > 0 && errors[0] != '') || currentError) && !disabled, 
                                'app-input-password': true,
                                }"

                            @keyup="onKeyUp"
                            @input="onInput" 
                            @keypress="onKeypress"
                            @change="onChange"
                            @blur="onBlur" 
                            @click="onClick"
                />
                <b-input-group-append class="d-flex app-local-icon app-input-password-icon text-muted  align-items-center" :class="{'app-password-icon-filled': update_value != null && update_value.length > 0, 'app-password-icon-disabled': disabled, 'app-password-icon-error': ((errors.length > 0 && errors[0] != '') || currentError) && !disabled}">
                    <div @click="disabled == false ? visible_password = true : '' " v-if="!visible_password"><b-icon icon="eye-fill" class="mr-1 app-local-icon-image"></b-icon></div>
                    <div @click="disabled == false ? visible_password = false : '' " v-else><b-icon icon="eye-slash-fill" class="mr-1 app-local-icon-image"></b-icon></div>
                </b-input-group-append>
            </b-input-group>

            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[1]">{{ errors[0] }}</small>  
        </validation-provider>
    </div>
   
</template>

<script>

import { password } from "@validations";
export default {
    name: 'AppInput',
    props: {

        id: { type: String },

        value: { },
        type: { type: String, default: "text"},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String, default: "required|password"},
        validatorCustomMessage: { type: Object },
        maxLength: { type: Number },
        autocomplete: {type: String, default: "on"},
        loading: {type: Boolean, default: false},
        label: {type: String},
        additionalInfo: {type: String},
        validatorId:{type: String, default: ''},
        customInputClass:{ type: String, default: "form-control form-control-sm"},
        currentError:{ type: Boolean, default: false}
    },

    created(){
        this.update_value = this.value;
        if(this.maxLength == null){
            this.customMaxLenght = false;
            this.cMaxLength = 60;
        }
        
    },
    
    watch:{

        "value":{
            handler: function(val) {
                this.update_value = val;              
            },
        },
    },

    computed: {
        cMaxLength: {
            get() {
                if(this.maxLength != null) return this.maxLength;
                else return this.maxLenghtFixed
            },
            set(value) {
                if(this.maxLength != null) this.$emit('update:maxLength', value);
                else this.maxLenghtFixed = value;
            },
        },

    
    },

    data() {
        return {
            inputType: "text",
            actualPosition: 0,
            visible_password: false,
            update_value: null,
            customMaxLenght: true,
            maxLenghtFixed:60,
        }
    },


  methods: {


    onInput(event) {
      
      this.update_value = event.target.value;
      this.$emit('input', event.target.value);
      
    },
    
    onChange(event) { 
      this.$emit('change', event.target.value);
    },

    onClick(event) { 
      this.$emit('click', event.target.value);
    },
    

    onBlur(event) {
      this.$emit('blur', event.target.value);
    },

    onKeyUp(event) {
       this.$emit('keyup', event.target.value);
       if(event.key == 'Enter'){
           this.$emit('enter', event.target.value);
       }
    },

    onKeypress($event){
        if ($event.charCode === 32){
            $event.preventDefault();
            return false;
        }
    },

    onDrag(event){},

    onDrop(event){
        this.update_value = event.dataTransfer.getData('text');
        this.$emit('input', event.dataTransfer.getData('text'));
    },
    
  },
}
</script>
<style scoped>
.app-local-input-spinner{
    position: absolute; 
    top: 32px; 
    right: 22px;
}

.app-local-icon-image{
    color: #174066 !important;
}

.app-input-group-error .app-local-icon-image{
    color: #d92d20 !important;
}


.app-local-copy{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}

.app-local-icon{
    border: 1px solid #dee2e6;
    border-left: none;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding-right:8px;
}

.app-local-icon:hover{
    cursor: pointer;
    color: #174066 !important;
}
    .input-group-append{
        margin-left: 0px !important;
    }

    .app-input-error-ellips{
        white-space: pre-wrap
    }

</style>