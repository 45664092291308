<template>
        <ValidationObserver ref="observer" >

                <b-row>
                    <b-col cols="12">
                        <b-form-group :label="label" label-for="vi-mobile" label-cols-md="4" class="app-local-input-label ">
                            <validation-provider v-slot="{  }" :rules="validatorRules" :name="label">
                                <b-input-group class="input-group-merge app-kvk" :class="{'app-kvk-error': (checked_kvk_key == '') && !disabled, 'app-kvk-disabled': disabled}">                            
                                    <input type="integer" id="vi-kvk-number" class="form-control form-control-sm app-kvk-input" :class="{'app-kvk-input-filled' : cKvkNumber && cKvkNumber.length > 0, 'app-kvk-input-error': (checked_kvk_key == '') && !disabled}" v-mask="'########'" :disabled="disabled" v-model="cKvkNumber" v-on:blur="checkKvk" @change="changingKvk" @keypress="preventLetterInput">
                                    <b-input-group-append class="d-flex app-input-error-icon align-items-center app-kvk-input-error-icon" v-if="(checked_kvk_key == '') && !disabled">
                                        <b-icon icon="exclamation-circle-fill"></b-icon>
                                    </b-input-group-append>
                                    <b-input-group-append class="app-kvk-append" :class="{'app-kvk-append-filled' : cKvkNumber && cKvkNumber.length > 0}">
                                        <b-button size="sm" text="Button" variant="transparent" @click="checkKvk" class="app-input-button-group app-kvk-icon" :class="{'app-kvk-icon-filled' : cKvkNumber && cKvkNumber.length > 0}">
                                            <b-icon icon="arrow-clockwise" v-if="!loadingCheck" :class="{'app-kvk-icon-error' : (checked_kvk_key == '') && !disabled }"/>
                                            <b-spinner small class="align-middle" v-if="loadingCheck"></b-spinner>
                                        </b-button>
                                    </b-input-group-append>                                
                                </b-input-group>
                            </validation-provider>
                            <app-input type="hidden" v-show="checkEmail == false || !clients_ids" v-model="checked_kvk_key" name="KVK" validatorRules="required" :validatorCustomMessage="{ required: 'Ongeldig nummer' }"/>
                        </b-form-group>
                    </b-col>
                 </b-row>

                 <b-row>
                    <b-col cols="12" v-if="f_information">
                        <b-form-group label="Informatie" label-for="vi-mobile" label-cols-md="4" class="app-local-input-label align-items-center">                            
                            <b-col class="p-0">
                                <b-card class="app-card-info" v-if="checkEmail == false || !clients_ids">
                                    <div class="font-weight-bolder kvk-data-inf-large">{{JSON.parse(cKvkData.kvk_data_main).names_list.map(item => item.naam).join(' | ')}}</div>
                                    <div class="kvk-data-inf-large">{{JSON.parse(cKvkData.kvk_data_main).street }}</div>
                                    <div class="kvk-data-inf-large">{{JSON.parse(cKvkData.kvk_data_main).city }}</div>
                                    <div class="kvk-data-inf-small">{{cKvkData.kvk_check_date}}</div>
                                </b-card>
                                <b-card class="app-card-warning" v-if="checkEmail == true && clients_ids">
                                    <div class="app-local-justify kvk-data-inf-small">Dit KVK nummer wordt ook al gebruikt bij organisatie {{clients_names}} met ID {{clients_ids}}</div>
                                </b-card>
                            </b-col>
                        </b-form-group>
                    </b-col>
                 </b-row>

               
        </ValidationObserver>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        kvkData: {type: Object},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        checkEmail: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label:{type: String},
    },

    created(){
       if (this.cKvkData.kvk_number !== undefined && this.cKvkData.kvk_number !== null && this.cKvkData.kvk_data_main !== null) {
           this.f_information = true;
           this.checked_kvk_number = this.cKvkData.kvk_number;
           this.basic_kvk = JSON.parse(JSON.stringify(this.cKvkData));
       } 
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cKvkData: {
            get() { return this.kvkData },
            set(value) { this.$emit('update:args', value) }
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cKvkNumber:{
            get() {return this.cKvkData.kvk_number},
            set(value) { this.clearError(value),  this.cKvkData.kvk_number = value}
        }
    },

    data() {
        return {
            loading: false,
            f_information: false,
            f_Incorrect_number: false,
            checked_kvk_key: "kvk",
            checked_kvk_number: "",
            basic_kvk: null,
            loadingCheck: false,
            opt: [ { value: 1, text: "Ja" }, { value: 0, text: "Nee" }],
            clients_ids:null,
            clients_names: null,
        };
    },
 
    methods: {
   
      changingKvk() {
           this.f_Incorrect_number = false;
           this.cKvkData.kvk_data_main = null;
           this.f_information = false;
           this.checked_kvk_number = "";
       },


       checkKvk() {
            if (this.cKvkData.kvk_number === undefined || this.cKvkData.kvk_number === null || (this.cKvkData.kvk_number !== null && this.cKvkData.kvk_number.toString().length !== 8) || (this.checked_kvk_number !== null && this.cKvkData.kvk_number !== null && this.checked_kvk_number.toString() === this.cKvkData.kvk_number.toString())) {
                return;
            } 
            if(this.basic_kvk && this.basic_kvk.kvk_number == this.cKvkData.kvk_number)  {
                this.f_information = true;
                this.checked_kvk_key = "kvk";  
                this.cKvkData.kvk_data_main = this.basic_kvk.kvk_data_main;
                this.cKvkData.kvk_check_date = moment().format('DD-MM-YYYY HH:mm:ss');
                this.clients_ids = null;
                return;
            }
                this.loadingCheck = true;
                this.f_information = false;
                this.cKvkData.kvk_data_main = null;

                axios
                    .get("core/kvk/check/" + this.cKvkData.kvk_number)
                    .then((res) => {
                        if(res.data !== -1) {
                            this.cKvkData.kvk_data_main = JSON.stringify(res.data);
                            this.cKvkData.kvk_check_date = moment().format('DD-MM-YYYY HH:mm:ss');
                            this.f_information = true;
                            this.f_Incorrect_number = false; 
                            this.checked_kvk_key = "kvk";    
                            this.clients_ids = res.data.clients_ids; 
                            this.clients_names = res.data.clients_names;  
                            if(this.clients_ids && this.checkEmail == true){
                                this.checked_kvk_number = "";
                                this.checked_kvk_key = "";
                            }             
                        } else {
                            this.f_information = false;
                            this.checked_kvk_key = "";
                            this.f_Incorrect_number = true; 
                            this.clients_ids = null;
                        }

                        this.checked_kvk_number = this.cKvkData.kvk_number;
                        this.loadingCheck = false;
                        
                    })
                    .catch((error) => {
                        this.loadingCheck = false;
                        console.error(`error during request: ${error}`);
                    });  
                    
                this.$refs.observer.validate();
        },

        preventLetterInput($event){
            if ($event.keyCode < 48 || $event.keyCode > 57 ) {
                $event.preventDefault();
            }
         },

         clearError(kvk){
            this.checked_kvk_key = 'kvk';
            if(this.checked_kvk_number == kvk && !this.f_Incorrect_number){
                this.f_information = true;
            }
            if(kvk.toString().length != 8){
                this.f_information = false;
            }
            if((kvk !== null 
                    && kvk.toString().length > 0 
                    && kvk.toString().length < 8) 
                    || (this.f_Incorrect_number && kvk !== null 
                        && kvk.toString().length === 8)){
                             this.f_information = false;
                             this.checked_kvk_key = '';
                        }
            if(this.$refs.observer != undefined){
                this.$refs.observer.validate();
            }
        },
    },
};
</script>

<style scoped>
.kvk-data-inf-large{
    font-size:14px
}

.kvk-data-inf-small{
    font-size:12px
}
.app-local-input-label{
    line-height: 1.2 !important;
    padding-top:0px !important;
    padding-bottom:0px !important;
}
.app-local-justify{
    text-align:justify;
}
</style>
