<template>
    <div>
        
        <ValidationObserver ref="form" >
            
        <h1> Styles</h1>
        <!-- BUTTONS -->
        <div class="my-3">
            
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Buttons </h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                        <b-row class="mt-3 ml-3 mr-3 mb-3">
                            <b-col>
                                <app-button type="primary">Primary</app-button> <app-button type="primary" size="xs">Primary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="secondary">Secondary</app-button> <app-button type="secondary" size="xs">Secondary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="secondary-grey">Secondary grey</app-button> <app-button type="secondary-grey" size="xs">Secondary grey</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="tertiary">Tertiary</app-button> <app-button type="tertiary" size="xs">Tertiary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="tertiary-grey">Tertiary grey</app-button> <app-button type="tertiary-grey" size="xs">Tertiary grey</app-button>
                            </b-col>
                        </b-row>

                        <b-row class="mt-3 ml-3 mr-3 mb-3">
                            <b-col>
                                <app-button type="primary" :disabled="true">Primary</app-button> <app-button type="primary" :disabled="true" size="xs">Primary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="secondary" :disabled="true">Secondary</app-button> <app-button type="secondary" :disabled="true" size="xs">Secondary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="secondary-grey" :disabled="true">Secondary grey</app-button> <app-button type="secondary-grey" :disabled="true" size="xs">Secondary grey</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="tertiary" :disabled="true">Tertiary</app-button> <app-button type="tertiary" :disabled="true" size="xs">Tertiary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="tertiary-grey" :disabled="true">Tertiary grey</app-button> <app-button type="tertiary-grey" :disabled="true" size="xs">Tertiary grey</app-button>
                            </b-col>
                        </b-row>

                        <b-row class="mt-3 ml-3 mr-3 mb-3">
                            <b-col>
                                <app-button type="primary" :disabled="true">Primary</app-button> <app-button type="primary" :disabled="true" size="xs" :loading="true">Primary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="secondary" :disabled="true">Secondary</app-button> <app-button type="secondary" :disabled="true" size="xs" :loading="true">Secondary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="secondary-grey" :disabled="true">Secondary grey</app-button> <app-button type="secondary-grey" :disabled="true" size="xs" :loading="true">Secondary grey</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="tertiary" :disabled="true">Tertiary</app-button> <app-button type="tertiary" :disabled="true" size="xs" :loading="true">Tertiary</app-button>
                            </b-col>

                            <b-col>
                                <app-button type="tertiary-grey" :disabled="true">Tertiary grey</app-button> <app-button type="tertiary-grey" :disabled="true" size="xs" :loading="true">Tertiary grey</app-button>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- CHECKBOXES -->
        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Checkboxes </h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                    <h4> Checkbox solo </h4>
                        <b-row class="mt-3 ml-3 mr-3 mb-5">
                            <b-col>
                                <app-check-box >Check box initial</app-check-box>
                            </b-col>
                            <b-col>
                                <app-check-box v-model="checked">Check box selected</app-check-box>
                            </b-col>
                            <b-col>
                                <app-check-box v-model="unchecked" :indeterminate="true" >Check box partially selected</app-check-box>
                            </b-col>
                            <b-col>
                                <app-check-box  :disabled="true">Check box disabled</app-check-box>
                            </b-col>
                            <b-col>
                                <app-check-box v-model="checked"  :disabled="true">Check box selected disabled</app-check-box>
                            </b-col>
                            <b-col>
                                <app-check-box v-model="unchecked" :indeterminate="true"  :disabled="true">Check box partially selected disabled</app-check-box>
                            </b-col>
                        </b-row>
                    <h4> Checkbox group </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col>
                                <app-checkbox-group  :options="list_types_checkboxes" />
                            </b-col>

                            <b-col>
                                <app-checkbox-group v-model="list_group_model" :options="list_types_checkboxes" />
                            </b-col>

                            <b-col>
                                <app-checkbox-group :disabled="true"  :options="list_types_checkboxes" />
                            </b-col>

                            <b-col>
                                <app-checkbox-group :disabled="true" v-model="list_group_model" :options="list_types_checkboxes" />
                            </b-col>

                        </b-row>
                    <h4> Radio group </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3">
                            <b-col>
                                <app-radio-group  validatorRules="required" :options="list_types" />
                            </b-col>


                            <b-col>
                                <app-radio-group v-model="radio_model" :options="list_types" />
                            </b-col>

                            <b-col>
                                <app-radio-group :disabled="true"  :options="list_types" />
                            </b-col>

                            <b-col>
                                <app-radio-group :disabled="true" v-model="radio_model" :options="list_types" />
                            </b-col>

                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- INPUTS -->
        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Inputs </h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                    <h4> Normal input </h4>
                        <b-row class="mt-3 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-input v-model="a" placeholder="Input" label="Input"/>
                            </b-col>
                            <b-col cols="3">
                                <app-input v-model="a" placeholder="Input" additionalInfo="Additional info" label="Input"/>
                            </b-col>
                            <b-col cols="3">
                                <app-input v-model="a" placeholder="Input" :disabled="true" label="Input"/>
                            </b-col>
                            <b-col cols="3">
                                <app-input v-model="a" validatorRules="required" label="Input"/>
                            </b-col>
                        </b-row>
                    <h4> Currency fields </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-input v-model="b" placeholder="Input" type="currency"  label="Currency"/>
                            </b-col>
                            <b-col cols="3">
                                <app-input v-model="b" placeholder="Input" type="currency" additionalInfo="Additional info"  label="Currency"/>
                            </b-col>
                            <b-col cols="3">
                                <app-input v-model="b" type="currency" :disabled="true" label="Currency"/>
                            </b-col>
                            <b-col cols="3">
                                <app-input v-model="b" type="currency" additionalInfo="Additional info" validatorRules="required" label="Currency"/>
                            </b-col>

                        </b-row>
                    <h4> Email </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-email :email.sync="email" placeholder="Email" label="Email"/>
                            </b-col>
                            <b-col cols="3">
                                <app-email :email.sync="email" placeholder="Email" additionalInfo="Additional info"  label="Email"/>
                            </b-col>
                            <b-col cols="3">
                                <app-email :email.sync="email" :disabled="true" label="Email"/>
                            </b-col>
                            <b-col cols="3">
                                <app-email :email.sync="email" additionalInfo="Additional info" validatorRules="required" label="Email"/>
                            </b-col>

                        </b-row>
                    <h4> Textareas </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                             <b-col cols="3">
                                <app-memo v-model="c" label="Text area"/>
                            </b-col>
                            <b-col cols="3">
                                <app-memo v-model="c" label="Text area"/>
                            </b-col>
                            <b-col cols="3">
                                <app-memo v-model="c" :disabled="true" label="Text area"/>
                            </b-col>
                            <b-col cols="3">
                                <app-memo v-model="c" type="currency" validatorRules="required" label="Text area"/>
                            </b-col>

                        </b-row>

                    <h4> Password </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-password id="pass" v-model="x" label="Wachtwoord"/>
                            </b-col>
                            <b-col cols="3">
                                <app-password v-model="x" label="Password"/>
                            </b-col>
                            <b-col cols="3">
                                <app-password id="pass" v-model="x" validatorName="Wachtwoord" :disabled="true" label="Wachtwoord"/>
                            </b-col>
                            <b-col cols="3">
                                <app-password id="pass" v-model="x" validatorName="Wachtwoord" validatorRules="required" label="Wachtwoord"/>
                            </b-col>
                        </b-row>
                    <h4> Search </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3">
                              <b-col cols="3">
                                <b-form-input type="text" v-model="search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': search.length > 0}" placeholder="Zoeken" size="sm" />
                            </b-col>
                            <b-col cols="3">
                                <b-form-input type="text" v-model="search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': search.length > 0}" placeholder="Zoeken" size="sm" />
                            </b-col>
                            <b-col cols="3">
                                <b-form-input type="text" v-model="search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': search.length > 0, 'app-input-disabled': true}" placeholder="Zoeken" size="sm" disabled />
                            </b-col>
                            <b-col cols="3">
                                <b-form-input type="text" v-model="search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': search.length > 0}" placeholder="Zoeken" size="sm" />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- DATEPICKERS -->
        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Date components</h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                    <h4> Datepicker </h4>
                        <b-row class="mt-3 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-date placeholder="Input" label="Datepicker"/>
                            </b-col>
                            <b-col cols="3">
                                <app-date v-model="test2" :clearable="true" label="Datepicker"/>
                            </b-col>
                            <b-col cols="3">
                                <app-date v-model="test" :disabled="true" label="Datepicker" :loading="true"/>
                            </b-col>
                            <b-col cols="3">
                                <app-date v-model="test2" :clearable="true" validatorRules="required" label="Datepicker"/>
                            </b-col>
                        </b-row>
                    <h4> Weekpicker </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                           <b-col cols="3">
                                <app-week placeholder="Input" v-model="f" label="Week"/>
                            </b-col>
                            <b-col cols="3">
                                <app-week v-model="f" :clearable="true" label="Week"/>
                            </b-col>
                            <b-col cols="3">
                                <app-week v-model="f" :disabled="true" label="Week"/>
                            </b-col>
                            <b-col cols="3">
                                <app-week v-model="f" :clearable="true" validatorRules="required" label="Week"/>
                            </b-col>
                        </b-row>
                    <h4> Timepicker </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-time v-model="g" placeholder="Input" label="Time"/>
                            </b-col>
                            <b-col cols="3">
                                <app-time v-model="g" :clearable="true" label="Time"/>
                            </b-col>
                            <b-col cols="3">
                                <app-time v-model="g" :disabled="true" label="Time"/>
                            </b-col>
                            <b-col cols="3">
                                <app-time v-model="g" :clearable="true" validatorRules="required" label="Time"/>
                            </b-col>
                        </b-row>
                    <h4> Yearpicker </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-year v-model="h" placeholder="Input" label="Year"/>
                            </b-col>
                            <b-col cols="3">
                                <app-year v-model="h" :clearable="true" label="Year"/>
                            </b-col>
                            <b-col cols="3">
                                <app-year v-model="h" :disabled="true" label="Year"/>
                            </b-col>
                            <b-col cols="3">
                                <app-year v-model="h" :clearable="true" validatorRules="required" label="Year"/>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- SELECTORS -->
        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Selectors</h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                    <h4> App select </h4>
                        <b-row class="mt-3 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-select placeholder="Selecteer locaties" v-model="e" label="App select" type="getUsers" />
                            </b-col>
                            <b-col cols="3">
                                <app-select v-model="e" label="App select" :clearable="true" type="getUsers" />
                            </b-col>
                            <b-col cols="3">
                                <app-select v-model="e" value="asdaggyyXX" label="App select" type="getUsers" :disable="true"/>
                            </b-col>
                            <b-col cols="3">
                                <app-select v-model="e" label="App select" type="getUsers" :clearable="true" validatorName="Locatie" validatorRules="required"/>
                            </b-col>
                        </b-row>
                    <h4> V-select </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                           <b-col cols="3">
                                <label class="app-input-top-label">V-select</label><br/>
                                <app-select v-model="d" placeholder="Selecteer locaties" :options="client_type_options" :clearable="false" />
                            </b-col>
                            <b-col cols="3">
                                <label class="app-input-top-label">V-select</label><br/>
                                <app-select v-model="d" :options="client_type_options"  />
                            </b-col>
                            <b-col cols="3">
                                <label class="app-input-top-label">V-select</label><br/>
                                <app-select v-model="d"   :options="client_type_options" disabled />
                            </b-col>
                            <b-col cols="3">
                                <validation-provider v-slot="{ errors }" rules="required" name="Select">
                                    <label class="app-input-top-label">V-select</label><br/>
                                    <app-select v-model="d" clearable :selectOnTab="true" :options="client_type_options" />
                                    <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error">{{ errors[0] }}</small> 
                                </validation-provider> 
                            </b-col>
                        </b-row>
                    <h4> Multi select </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3">
                            <b-col cols="3">
                                <app-multi-select placeholder="Selecteer locaties" labelPosition="top" v-model="k" label="App multi select" type="getUsers" />
                            </b-col>
                            <b-col cols="3">
                                <app-multi-select v-model="k" label="App multi select" :clearable="true" labelPosition="top" type="getUsers" />
                            </b-col>
                            <b-col cols="3">
                                <app-multi-select v-model="k" label="App multi select" labelPosition="top" type="getUsers" :disabled="true"/>
                            </b-col>
                            <b-col cols="3">
                                <app-multi-select v-model="k" label="App multi select" labelPosition="top" type="getUsers" :clearable="true" validatorName="Multi select" validatorRules="required"/>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> API components</h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                    <h4> BIG </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="4">
                                <app-big ref="big_control" :bigData.sync="big_data"/>
                            </b-col>
                        </b-row>
                    <h4> BIG Wizard</h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="6">
                                <app-wizard-big ref="big_control" :bigData.sync="big_data"/>
                            </b-col>
                        </b-row>

                    <h4> Adress </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="4">
                                <app-address :address.sync="address"/>
                            </b-col>
                        </b-row>
                    <h4> Adress wizard </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="6">
                                <app-wizard-address :address.sync="address"/>
                            </b-col>
                        </b-row>
                    <h4> KVK </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3 pb-4">
                            <b-col cols="4">
                                <app-kvk :kvkData.sync="kvk_data" />
                            </b-col>
                        </b-row>


                        <h4> KVK wizard</h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3 pb-4">
                            <b-col cols="6">
                                <app-wizard-kvk :applicationData.sync="kvk_data" />
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- REST -->
        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Another components</h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                    <h4> App phone </h4>
                        <b-row class="mt-3 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-phone :prefix.sync="phone_prefix" :phone.sync="phone_number" :isRequired="false" label="Phone"/>
                            </b-col>
                            <b-col cols="3">
                                <app-phone :prefix.sync="phone_prefix" :phone.sync="phone_number" :isRequired="false"  label="Phone"/>
                            </b-col>
                            <b-col cols="3">
                                <app-phone :prefix.sync="phone_prefix" :phone.sync="phone_number"  validatorName="Phone" :isRequired="false" :disabled="true" label="Phone"/>
                            </b-col>
                            <b-col cols="3">
                                <app-phone :prefix.sync="phone_prefix" :phone.sync="phone_number" validatorName="Phone" validatorRules="required" label="Phone"/>
                            </b-col>
                        </b-row>
                    <h4> Value per year </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-5">
                            <b-col cols="3">
                                <app-value-per-year v-model="value_py" label="Value per year"/>
                            </b-col>
                            <b-col cols="3">
                                <app-value-per-year v-model="value_py" label="Value per year"/>
                            </b-col>
                            <b-col cols="3">
                                <app-value-per-year v-model="value_py" validatorName="Value per year" :disabled="true" label="Value per year"/>
                            </b-col>
                            <b-col cols="3">
                                <app-value-per-year v-model="value_py" validatorName="Value per year" validatorRules="required" label="Value per year"/>
                            </b-col>
                        </b-row>
                        <h4> Aliasses </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3">
                            <b-col cols="3">
                                 <app-tags v-model="name_alias" label="Tags"/>
                            </b-col>

                            <b-col cols="3">
                                 <app-tags v-model="name_alias2" label="Tags"/>
                            </b-col>

                            <b-col cols="3">
                                 <app-tags disabled v-model="name_alias" label="Tags"/>
                            </b-col>

                            <b-col cols="3">
                                 <app-tags disabled v-model="name_alias2" label="Tags"/>
                            </b-col>
                        </b-row>


                        <h4> Slider </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3 pb-4">
                            <b-col cols="4">
                                <vue-slider />
                            </b-col>
                            <b-col cols="4">
                                <vue-slider disabled />
                            </b-col>
                            <b-col cols="4">
                                <vue-slider v-model="weekend_values" tooltip="always" tooltip-placement="bottom" :marks="true" :hide-label="true" :data="weekend_scale" :data-value="'id'" :data-label="'display_hour'" 
                                    :process="weekend_process" :min-range="2" :dot-options="weekend_limitation" :adsorb="true" :lazy="true" :duration="0" :tooltipStyle="tooltip_style">
                                </vue-slider>
                            </b-col>
                        </b-row>

                        <h4> AppSlider </h4>
                        <b-row class="mt-4 ml-3 mr-3 mb-3 pb-4">
                            <b-col cols="4">
                                <app-slider v-model="slider_values" min="10" max="150" step="10" :key="refresh" showValue markLabel />
                            </b-col>
                        </b-row>

                            <h4> Badge </h4>
                            <b-row class="mt-4 ml-3 mr-3 mb-3 pb-4">
                                <b-col>
                                    <app-badge type="neutral">Neutral</app-badge>
                                </b-col>
                                <b-col>
                                    <app-badge type="succes">Succes</app-badge>
                                </b-col>
                                <b-col>
                                    <app-badge type="pending">Pending</app-badge>
                                </b-col>
                                <b-col>
                                    <app-badge type="warning">Warning</app-badge>
                                </b-col>

                                <b-col>
                                    <app-badge type="attention">Attention</app-badge>
                                </b-col>
                            </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        

        <!-- APPVIEWTABLE -->
        <div class="my-3">
            <b-row class="match-height" >
                <b-col cols="12" class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 pr-3 pr-lg-2">
                    <b-card>
                    <h2> Grids</h2>
                    <app-divider-modal :isLine="true" class="mb-3"/>
                           <app-view-table title="Nationale feestdagen" class="mt-4" :filterOptions="user_status_list" :isFilterOptions="true" :loading="loading_list"  :isSearch="true" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :customColumns="customColumns" :customColumnsHeaders="customColumns"> 

                                <template v-slot:menu-items>
                                    <b-dropdown-item >Wijzigen</b-dropdown-item> 
                                    <b-dropdown-item >Verwijderen</b-dropdown-item>
                                </template> 

                                <template v-slot:buttons>
                                    <b-dropdown v-if="selected_items>0" no-caret toggle-class="app-button-multi mr-4" variant="secondary-grey">
                                        <template #button-content>
                                            Actie ({{selected_items}})                         
                                        </template>
                                           
                                        <b-dropdown-item>Wizijgen</b-dropdown-item>                             
                                        <b-dropdown-item>Annuleren</b-dropdown-item>                        
                
                                    </b-dropdown>

                                    <app-button type="add" >Nationale feestdagen toevoegen</app-button>
                                </template>

                                <template v-slot:head_selected>
                                    <app-check-box v-model="select_all" value="1" unchecked-value="0" v-on:change="selectAll" />
                                </template>

                                <template v-slot:selected="cell">
                                    <app-check-box v-model="cell.data.item.selected" value="1" unchecked-value="0" v-on:change="selectedChanged(cell.data.item)" />
                                </template>
                                
                            </app-view-table>
                    </b-card>
                </b-col>
            </b-row>
        </div>
         
        </ValidationObserver>
    </div>
</template>

<script>
import axios from "@axios";
import internationals_json from "@/assets/json/internationals.json";
import AppPassword from "@core/components/AppPassword.vue"
import AppBig from "@core/components/AppBig.vue"
import AppWizardBig from "@core/components/Big/AppWizardBig.vue"
import AppValuePerYear from '@core/components/AppValuePerYear.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppTags from '@core/components/AppTags.vue';
import AppKvk from '@core/components/AppKvk.vue';
import AppWizardKvk from '@core/components/Kvk/AppWizardKvk.vue';
import AppAddress from '@core/components/Address/AppAddress.vue';
import AppWizardAddress from '@core/components/Address/AppWizardAddress.vue';
import VueSlider from 'vue-slider-component'
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        AppPassword,
        AppValuePerYear,
        AppPhone,
        AppBig,
        AppTags,
        VueSlider,
        AppAddress,
        AppWizardAddress,
        AppKvk,
        AppAddress,
        AppWizardKvk,
        AppWizardBig,
        ValidationObserver
    },

    

    computed: {
        cError() {
            return this.$refs.tags != null && this.$refs.tags._data.tagsState.duplicate.lenght > 0
        }
    },

    data() {
        return {
            loading: true,
            loading_list: false,
            name_alias:['test','test2'],
            name_alias2:[],
            filters: {
                sorting: {
                        field_name: 'date',
                        sort_direction: 1,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                duplicate: false,
                
                filtering: {                        
                        users: { type: 'list', changed: 0, values: [], function: "getUsers", parameters: null, widthClass:'app-width-min-350'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        statuses: { type: 'list', changed: 0, values: [], function: "getApplicantStatuses", parameters: null, widthClass:'app-width-min-250'},
                        locations: { type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'}
                },
            },
            userName: JSON.parse(localStorage.getItem('user-portal')).user_name,
            slide: 0,
            sliding: null,
            checked: 1,
            duplicate: false,
            unchecked: 0,
            test: "",
            test2: null,
            slider_values: 30,
            refreshClientLocations: 1,
            a: '',
            b: '',
            c: '',
            e: null,
            d: null,
            f: null,
            x: null,
            big_data: {big_number: null, big_data_main: []},
            email: '',
            g: null,
            h: null,
            phone_prefix: null,
            phone_number: null,
            k: [],
            search: '',
            value_py: null,
            client_type_options:[
                { value: 0, name: "Large" },
                { value: 1, name: "National" },
                { value: 2, name: "Regulier" }
            ],
            internationals: internationals_json,
            items: {
                "list": [
                    {
                        "selected": "0",
                        "id_freedays_data_main": 55,
                        "date": "31-12-2022",
                        "name": "Oudjaarsavond",
                        "time_from": "18:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 31,
                        "date": "26-12-2022",
                        "name": "Kerstmis",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 30,
                        "date": "25-12-2022",
                        "name": "Kerstmis",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 29,
                        "date": "06-06-2022",
                        "name": "Pinksteren",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 28,
                        "date": "05-06-2022",
                        "name": "Pinksteren",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 27,
                        "date": "26-05-2022",
                        "name": "Hemelvaartsdag",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 33,
                        "date": "05-05-2022",
                        "name": "Bevrijdingsdag",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 26,
                        "date": "27-04-2022",
                        "name": "Koningsdag",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 25,
                        "date": "18-04-2022",
                        "name": "Pasen",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    },
                    {
                        "selected": "0",
                        "id_freedays_data_main": 24,
                        "date": "17-04-2022",
                        "name": "Pasen",
                        "time_from": "00:00",
                        "time_to": "23:59"
                    }
                ],
                "total": 12,
                "refresh": 0
            },

            address: {
                                type: 1,
                                post_code: "", 
                                house_number: "", 
                                house_number_additional:"",
                                city:"",
                                street:"",
                                latitude: null,
                                longitude: null
                            },

            weekend_limitation: [{ max: 8}, {min : 12}],
            weekend_process: dotsPos => [
                    [dotsPos[0], dotsPos[1]]
                ],
            weekend_scale:  [{id: 1, hour: '17:00', display_hour: 'Vr 17:00'}, {id: 2, hour: '18:00', display_hour: 'Vr 18:00'}, {id: 3, hour: '19:00', display_hour: 'Vr 19:00'}, 
                                 {id: 4, hour: '20:00', display_hour: 'Vr 20:00'}, {id: 5, hour: '21:00', display_hour: 'Vr 21:00'}, {id: 6, hour: '22:00', display_hour: 'Vr 22:00'}, 
                                 {id: 7, hour: '23:00', display_hour: 'Vr 23:00'}, {id: 8, hour: '00:00', display_hour: 'Za 00:00'}, {id: 9, hour: '12:00', display_hour: 'Za 12:00'}, 
                                 {id: 10, hour: '00:00', display_hour: 'Zo 00:00'}, {id: 11, hour: '12:00', display_hour: '12:00'}, {id: 12, hour: 'Ma 00:00', display_hour: 'Ma 00:00'}, 
                                 {id: 13, hour: '01:00', display_hour: 'Ma 01:00'}, {id: 14, hour: '02:00', display_hour: 'Ma 02:00'}, {id: 15, hour: '03:00', display_hour: 'Ma 03:00'}, 
                                 {id: 16, hour: '04:00', display_hour: 'Ma 04:00'}, {id: 17, hour: '05:00', display_hour: 'Ma 05:00'}, {id: 18, hour: '06:00', display_hour: 'Ma 06:00'}, 
                                 {id: 19, hour: '07:00', display_hour: 'Ma 07:00'}],
            tooltip_style: {
                                    color: '#174066',
                                    background: 'transparent',
                                    borderColor: 'transparent',
                                    transform: 'translate(-50%, -4px)',
                                    position: 'absolute'
                               },
            weekend_values: [8, 12],
            kvk_data: {kvk_number: null},
            kvk_data2: {kvk_number: null},
            kvk_data3: {kvk_number: null},
            devtools: 1,

            list_types: [
                { value: 1, text: "Radiobutton initial" },
                { value: 2, text: "Radiobutton initial 2" },
                { value: 3, text: "Radiobutton initial 3" }
            ],

            list_types_checkboxes: [
                { value: 1, text: "Checkbox initial" },
                { value: 2, text: "Checkbox initial 2" },
                { value: 3, text: "Checkbox initial 3" }
            ],


            user_status_list: [
                { value: 1, text: "Sollicitanten per vestiging" },
                { value: 2, text: "Mijn sollicitanten" },
                { value: 3, text: "Nieuw via RecruitNow" },                
                { value: -1, text: ""},
                { value: 5, text: "Alle sollicitanten" },
                { value: -2, text: ""},   
                { value: 4, text: "Ingetrokken" },
                { value: 6, text: "Afgewezen" }, 
            ],
            radio_model: 1,
            list_group_model: [1],

             fields: [
                
                { key: "selected", label: "", tdClass: "py-0 app-local-max-width", visible: true, thStyle: { "min-width": "40px", "max-width": "40px" } },
                { key: "name", label: "Naam", thStyle: { width: "100%"  }, visible: true, filtering: true, filtering_name: "users"},
                { key: "date", label: "Datum", thStyle: { "min-width": "180px", "max-width": "180px"}, visible: true, },
                { key: "time_from", label: "Van", thStyle: { "min-width": "150px", "max-width": "150px"}, visible: true},
                { key: "time_to", label: "Naar", thStyle: { "min-width": "150px", "max-width": "150px"}, visible: true}
            ],
            customColumns: ['selected'],
            select_all: 0,
            selected_items: 0,

        };
    },
    
    methods: {
        tagValidator(tag) {
            if(tag.length > 60){
                return false;
            }else{
                return true;
            }
        },

        selectAll(){
            this.items.list.forEach(element => {
                element.selected = this.select_all;
            });
            this.selected_items = this.select_all == '1' ? this.items.list.length : 0;
        },

        selectedChanged(item){
            if(item.selected == 1){
                this.selected_items++;
            } else if(item.selected == 0){
                this.selected_items--;
                this.select_all = '0';
            }
        }
    },
};
</script>

<style scoped>

.app-local-width{
    max-width: 20px;
}


</style>
<style>

.app-button-multi{
    border-radius: 50rem !important;
    height: 34px !important;
}
</style>
