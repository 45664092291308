<template>

    <div>
        <validation-provider v-slot="{ errors }" :rules="validatorRules" :name="validatorName">
            <label v-if="label" class="app-input-top-label">{{ label }}</label>
            <b-form-tags class="app-tags-edit  app-input app-tags" ref="tags" @tag-state="checkEror"
                :disabled="disabled" :state="duplicate || (errors.length > 0 && errors[0] != '') ? false : null"
                input-id="tags-remove-on-delete"
                :class="{ 'app-tags-error': duplicate == true || (errors.length > 0 && errors[0] != ''), 'app-input-filled': cModel != null && cModel.length > 0, 'app-tags-edit-disabled': disabled }"
                :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }" v-model="cModel"
                :placeholder="cModel != null && cModel.length == 0 ? placeholder : ''" remove-on-delete
                :tag-validator="tagValidator" size="sm" :invalid-tag-text="invalidTagMessage"
                :duplicate-tag-text="trans('dupplicate-tags', 314)"></b-form-tags>
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error">{{ errors[0] }}</small>
            <div style="min-height: 1.5rem;">
                <small v-show="($refs.tags && $refs.tags.newTag) && errors.length === 0 && !duplicate"
                    class="ml-1 text-error">{{ trans('confirm-enter', 281) }}</small>
            </div>
        </validation-provider>
    </div>
</template>

<script>

export default {

    props: {
        value: { type: Array },
        placeholder: { type: String, default: null },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        maxLenght: { type: Number, default: 60 },
        label: { type: String },
        disabled: { type: Boolean, default: false },
        invalidTagMessage: { type: String, default: null },
        duplicateTagMessage: { type: String, default: null },
        newTag: { type: String, default: '' }
    },


    computed: {
        cModel: {
            get() { return this.value },
            set(value) { this.$emit('input', value) }
        }
    },

    watch: {
        newTag: {
            handler: function (val) {
                if (val != '') {
                    this.cModel.push(val);
                }
            },
            immediate: true
        },
        cModel: {
            handler: function (val) {
                if (this.$refs) {
                    this.setNewTags();

                }
                if (this.invalidTagMessage === null) {
                    this.$emit('update:invalidTagMessage', this.trans('wrong-alias-name', 314));
                }
                if (this.duplicateTagMessage === null) {
                    this.$emit('update:duplicateTagMessage', this.trans('dupplicate-tags', 314));
                }
                if (this.placeholder === null) {
                    this.$emit('update:placeholder', this.trans('enter-new-tags', 314));
                }
            }
        }
    },

    created() {
        this.oldTags = JSON.parse(JSON.stringify(this.cModel));

    },

    mounted() {
        if (this.newTag != null && this.newTag != '' && !this.cModel.includes(this.newTag.toLowerCase())) {
            this.cModel.push(this.newTag);
            this.setNewTags(true);
        } else if (this.newTag != '') {
            this.$refs.tags.newTag = this.newTag;
        }

        if (this.isTags == true) {
            var inputs = document.querySelectorAll("[id='tags-remove-on-delete']");
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].disabled = true;
            }
        }

        this.$nextTick(() => {
            const input = this.$refs.tags.$el.querySelector('input');
            input.addEventListener('input', this.handleInput);
        });
    },


    data() {
        return {
            oldTags: [],
            duplicate: false,


        };
    },

    methods: {
        checkEror(validTags, invalidTags, duplicateTags) {
            if (invalidTags.length > 0 || duplicateTags.length > 0) {
                this.duplicate = true;
                this.$emit('update:error', true);

            } else {
                this.duplicate = false;
                this.$emit('update:error', false);

            }

        },

        handleInput(event) {
            const newTag = event.target.value;
            if (newTag && !this.cModel.includes(newTag)) {
                this.$emit('update:error', true);
            } else if (this.cModel.length > 0 && !this.duplicate) {
                this.$emit('update:error', false);
            }
        },


        tagValidator(tag) {
            if (this.cModel.map(item => item.toLowerCase()).includes(tag.toLowerCase()) && !this.$refs.tags.tagsState.duplicate.includes(tag)) {
                this.duplicate = true;
                this.$emit('update:error', this.duplicate);
                this.$refs.tags.tagsState.duplicate.push(tag);
                this.$refs.tags.tagsState.valid.pop();
                return false;
            }

            if (tag.length > this.maxLenght || this.$refs.tags.tagsState.duplicate.length > 0) {
                this.$emit('update:error', true);
                return false;
            } else {
                this.$emit('update:error', false);
                return true;
            }
        },

        async setNewTags(firstTime = false) {
            if (firstTime) {
                await this.$nextTick();
            }

            var tags = this.$refs.tags.$el.getElementsByClassName('badge');
            for (let item of tags) {
                if (this.oldTags && !this.oldTags.includes(item.textContent.substring(0, item.textContent.length - 1))) {
                    item.classList.add("new-tag");
                }
            }
        }
    },
};
</script>

<style scoped>
</style>
