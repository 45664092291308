<template>
    <div>
        <app-row-left-label class="mb-2" :slots="[6,6]" :isMarginBetween="false">
            <template v-slot:col-1>
                <app-input label="Vul hier je KVK nummer in" :disabled="disabled" :currentError="status == 2" additionalInfo="Bestaand uit een 8-cijferig nummer" v-model="cApplicationData.kvk_number" validatorName="KVK nummer" validatorRules="required|digits:8" mask="########" @blur="checkKVK()"/>
            </template>
            <template v-slot:col-2>
                <app-row-left-label v-if="loading || status == 1" >            
                        <b-card no-body class="app-local-card-min-height app-card-info">
                            <div class="app-info-card-label" v-if="loading">
                                <b-spinner class="app-local-spinner"/>Controleren..
                            </div>
                            <div class="app-info-card-label" v-if="!loading && status === 1">
                                <label class="flabel">KVK gegevens</label>
                                <div> {{ kvk.trade_name }} </div>
                                <div> {{ kvk.street }} {{kvk.city}}</div>
                            </div>
                        </b-card>
                </app-row-left-label>

                <app-row-left-label v-if="!loading && status == 2" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">Verkeerd nummer</label>
                            </div>
                        </b-card>
                </app-row-left-label>
            </template>
        </app-row-left-label>     
    </div>
</template>
<script>
import axios from "@axios";

export default {

    components:{

    },

    props: {
        applicationData: {type: Object},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
    },

    created(){
        this.showKVK();
    },

    computed: {
        cApplicationData: {
            get() { return this.applicationData },
            ste(value) { this.$emit("update:applicationData", value)}
        }
    },

    data(){
        return{
            status: 0,
            kvk: {
                trade_name: null,
                city: null,
                street: null
            },
            loading: false,
        }
    },

    methods: {

        checkKVK() {
            if (this.cApplicationData.kvk_number === undefined || this.cApplicationData.kvk_number === null || this.cApplicationData.kvk_number.toString().length !== 8) {
                this.status = 0;
                this.kvk.trade_name = null;
                this.kvk.city = null;
                this.kvk.street = null;

                return;
            }
            
            this.loading = true;

            this.kvk.trade_name = null;
            this.kvk.city = null;
            this.kvk.street = null;

            axios
                .get("core/kvk/check/" + this.cApplicationData.kvk_number)
                .then((res) => {
                    if(res.data == -1){
                        this.status = 2;
                    }else{
                        this.kvk = res.data;
                        this.cApplicationData.kvk_data_main = JSON.stringify(res.data);
                        this.status = 1;
                    }
                })
                .catch((error) => {
                    console.error(`error during checking KVK: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                });
        },

        showKVK() {
            if (this.cApplicationData.kvk_number !== undefined && this.cApplicationData.kvk_number !== null && this.cApplicationData.kvk_data_main !== null) {
                this.kvk = JSON.parse(this.applicationData.kvk_data_main)

                this.status = 1;
                this.loading = false;
            } else if (this.cApplicationData.kvk_number !== undefined && this.cApplicationData.kvk_number !== null && this.cApplicationData.kvk_data_main === null) {
                this.status = 2;
            } else {
                this.status = 0;
            }
        },

    }
    
}
</script>
<style scoped>
.app-local-card-min-height {
    min-height: 96px;
    padding: 1rem 1.5rem;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
    font-size: 11px;
}
</style>