<template> 
    <b-form>

        <app-row-left-label label="Land">
            <app-radio-group v-model="cAddress.type" :options="address_type" @change="clearAddress"/>
        </app-row-left-label>

        <app-address-nl :required="required" v-if="actual_address_type == 1" :address.sync="cAddress"/>
        <app-address-other :required="required" v-if="actual_address_type == 3" :address.sync="cAddress"/>

    </b-form>
</template>

<script>

import AppAddressNl from './AppAddressNl.vue';
import AppAddressOther from './AppAddressOther.vue';

export default {
    components: {
        AppAddressNl,
        AppAddressOther

    },

    props: {
        address: {},
        required: { type: Boolean, default: false }
    },

    computed: {
        cAddress:{
            get() { return this.address },
            set(value) { this.$emit('update:address', value) }
        },
       
    },

    data() {
        return {
           actual_address_type: this.address.type,
           clearReady: false,
            address_type: [
                { value: 1, text: "Nederland" },
                { value: 3, text: "Anders" },
            ],
        };
    },

     methods: {
        clearAddress() {
            this.cAddress.post_code = "";
            this.cAddress.city = "";
            this.cAddress.house_number = "";
            this.cAddress.house_number_additional = "";
            this.cAddress.street = "";
            this.cAddress.latitude = null;
            this.cAddress.longitude = null;
            this.actual_address_type = this.cAddress.type;
            this.cAddress.country = null;      
        }
     }

};
</script>

