<template>
    <b-row>
            <b-col cols="4" class="pr-5px">                
                <app-select v-model="cPrefix" :clearable="false" :selectOnTab="true" :options="internationals" :disabled="disabled" @input="changePrefix" :class="{'app-select-filled': cPrefix}"/>
            </b-col>
            <b-col cols="8" class="pl-5px">
                <app-input v-model="cNumber" :disabled="disabled" :mask="phoneMask" :validatorRules="isRequired == true ? phoneRulesRequired : phoneRules" :dontShowError="true" @blur="setError()"/>
            </b-col>
            <validation-provider v-slot="{ errors}" ref="provider" :rules="isRequired == true ? phoneRulesRequired : phoneRules" name="Telefoonnummer">
                <input type="hidden" name="date" v-model="cNumber">
                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-3 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
            </validation-provider>
        </b-row> 
</template>
<script>

import internationals_json from "@/assets/json/internationals.json";
import { extend } from 'vee-validate';

export default {
    props: {
            prefix: {
                type: String,
                default: "+31"
            },

            phone: {
                type: String
            },

            isRequired: { type: Boolean, default: true},
            disabled: {type: Boolean, default: false}
    },

    mounted() {
        extend('startsWithZeroIfTenLong', {
            validate: value => !(value.length === 10 && value[0] !== '0'),
            message: () => this.trans('phone_number_invalid', 199)
        });
    },

    computed: {
        
        cPrefix: {
            get() { return this.prefix },
            set(value) {this.$emit('update:prefix', value) }
        },

        cNumber: {
            
            get() { return this.phone },
            set(value) {this.$emit('update:phone', value) }
        }    
    },

    data() {
        return {
            internationals: internationals_json,
            phoneMask: "##########",
            phoneRules: "min:9",
            phoneRulesRequired: "required|min:9"
        };
    },

    methods: {

        changePrefix(value) {

            if (value == '+31') {
                this.phoneMask = "##########";
                this.phoneRules = "min:8|startsWithZeroIfTenLong";
                this.phoneRulesRequired = "required|min:8|startsWithZeroIfTenLong";
            } else {
                this.phoneMask = "###############";
                this.phoneRules = "min:5";
                this.phoneRulesRequired = "required|min:5";
            }
        },

        setError(){
            if(!this.cNumber || this.cNumber.length == 0 && this.isRequired == true){
                this.$refs.provider.setErrors([ 'Telefoonnummer is een verplicht veld' ]);
            }
            this.$refs.provider.validate();
        }
      
    }

};
</script>

<style>

</style>